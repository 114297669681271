<template>
  <div class="introduce">
    <div class="bottom" @click="toBottom()" v-if="detect">
      <img
        style="width: 30px"
        src="../../../../assets/imgs/向下占行.png"
        alt=""
      />
    </div>
    <div class="detect">
      <div class="detect_left">
        <div class="left_title">
          <img
            src="../../../../assets/imgs/Writefull素材/icon/Writefull+Logo+Padding+250.png"
            alt=""
            style="width: 40px"
          />
          <span>【语法校对系统介绍】</span>
        </div>
        <div class="txt">
          <p style="width: 425px; lin-height: 30px">
            Writefull是一款基于人工智能的专业学术写作辅助工具，由英国Digital
            Science（旗下著名产品包括Altmetric、Dimensions、Schloarcy、Read-Cube
            Papers等）专家团队开发。能够帮助作者改进论文的措辞、语法与学术表达。
          </p>
        </div>
      </div>
      <img
        class="right_img"
        src="../../../../assets/imgs/Writefull素材/Analytics_PNG.png"
        alt=""
      />
      <!-- <svg
        class="wave-bg"
        viewBox="0 0 1440 962"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60 164.878L0 149.878V584V585V962H1440V585V584V63.8779L1380 41.8779C1379.26 41.6185 1378.51 41.3558 1377.75 41.09C1317.04 19.8279 1198.52 -21.6775 1080 13.8779C1014.17 33.6279 948.333 77.1548 882.5 120.682C828.333 156.495 774.167 192.308 720 214.878C600 265.878 480 250.878 360 229.878C253.643 210.379 147.286 185.381 82.7026 170.202C74.4183 168.255 66.8214 166.47 60 164.878Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="720"
            y1="0"
            x2="720"
            y2="963"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#CEF8FF" />
            <stop offset="0.541667" stop-color="#CBDAFC" />
            <stop offset="0.984375" stop-color="#CBDAFC" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg> -->
      <div class="detect_right">
        <!-- <img src="../../../../assets/imgs/查重与校对/scene-a.png" alt="" /> -->
      </div>
    </div>
    <div>
      <!--  style="box-shadow: 100px -100px 100px 50px #d6e2fc" -->
      <div class="see" v-if="concise">
        <div class="check_btn">
          <el-button size="small" type="primary" @click="view" class="function"
            >查看功能介绍</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="checkGo"
            style="z-index: 10; margin-top: 30px; width: 100px; font-size: 16px"
            >去校对</el-button
          >
        </div>
      </div>
    </div>

    <div v-if="detect">
      <section class="content part2">
        <div class="pic" style="border: 5px solid #eab706">
          <img
            src="../../../../assets/imgs/Writefull素材/grammar-post-it-notes-with-cofee-cup.jpg"
            alt
          />
        </div>

        <div class="txt_wrap w_620" style="margin-top: -30px">
          <div class="title">
            <img src="../../../../assets/imgs/查重与校对/旗帜.png" alt />
            <span class="red">【检测系统专业性】</span>
          </div>

          <ul class="txt m_t_30">
            <li>
              <!-- <h3 class="txt_h3">1. 语法校对工具专业权威</h3> -->
              <p>
                Writefull采用了国际知名发现系统与引文数据库作为对比建议数据源。学科覆盖基础科学、工程技术、生物医药、农业科学、艺术人文与社会科学在内的所有学科。相较于同类辅助工具更全面、更准确、更专业，该工具已被世界各地的科研人员、研究机构与学术出版商广泛应用，能够充分满足各类型用户学术文章的写作需求。
              </p>
            </li>
            <!-- <li>
              <h3 class="txt_h3">2. 语法校对软件校对范围广泛</h3>
              <p>
                Grammarly校对检查范围超过250条语法规则，常见有拼写/语法/标点符号错误、赘词、文章风格不一致、句意模棱两可、高级词汇替换、限定词多余/矛盾、礼貌用语/敬语、语言随意程度、时态单一等拼写或语法问题。
              </p>
            </li> -->
          </ul>
        </div>
      </section>

      <!-- 校对格式要求 -->
      <section class="content m_t_50">
        <div class="txt_wrap w_620">
          <div class="title">
            <img src="../../../../assets/imgs/查重与校对/图标01.png" alt />
            <span class="red">【校对格式与要求】</span>
          </div>

          <ul class="txt m_t_30">
            <li>
              <h3 class="txt_h3">Writefull支持校对TXT、DOC、DOCX文档。</h3>
            </li>
          </ul>
        </div>
        <div class="pic m_r_30">
          <img
            src="../../../../assets/imgs/Writefull素材/are4n-qukrv.png"
            alt
          />
        </div>
      </section>
      <!-- 常见的问题 -->

      <section class="content m_t_30">
        <div class="m_t_30 p_t_100 m_l_30">
          <img
            src="../../../../assets/imgs/Writefull素材/Snipaste_2022-06-17_10-24-48.png"
            alt
            style="width: 500px;height:500px;"
          />
        </div>

        <div class="txt_wrap w_620">
          <div class="title">
            <img src="../../../../assets/imgs/查重与校对/图标04.png" alt />
            <span class="red">【常见问题】</span>
          </div>

          <ul class="txt m_t_30">
            <li>
              <h3 class="txt_h3">1.Writefull语法校对需要多久？</h3>
              <p>
                Writefull校对一般需5分钟左右出报告，在该平台上，检测报告只保留7天，请及时下载。
              </p>
            </li>
            <li class="report">
              <h3 class="txt_h3">2. 如何查看校对报告？</h3>
              <p>
                Writefull语法校对报告分为检测统计、错误类型统计、原文校对、错误建议列表四大部分内容。
              </p>
              <p>报告生成日期；</p>
              <p>校对文章字词数据统计；</p>
              <p>校对文章校对错误类型统计；</p>
              <p>校对文章原文及修改建议；</p>
              <p>各类型错误修改建议明细；</p>
            </li>
          </ul>
        </div>
      </section>
      <div class="one_btn">
        <el-button size="small" type="primary" @click="checkGo"
          >去校对</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      concise: true,
      //   detail: false,
      detect: false,
    };
  },
  created() {},
  methods: {
    // 查看功能介绍
    view() {
      this.concise = false;
      this.detect = true;
    },
    // 显示查重页面
    checkGo() {
      this.$emit("checkup", "true");
    },
    toBottom() {
      // 滚动内容的坐标位置0,50000：
      window.scrollTo(0, 50000);
    },
  },
};
</script>

<style  lang='less' scoped>
.introduce {
  // height: 100vh;
  width: 1200px;
  .bottom {
    z-index: 10;
    // background: rgb(223, 223, 223);
    position: fixed;
    bottom: 150px;
    right: 100px;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    font-size: 20px;
    cursor: pointer;
    i {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
  }
  .detect {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    .right_img {
      width: 300px;
      //   height: 400px;
      position: absolute;
      top: 20px;
      right: 200px;
      z-index: 2;
    }
    .banner_svg {
      width: 30%;
      height: 400px;
      position: absolute;
      top: 20px;
      right: 0;
      z-index: 2;
    }
    .wave-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0;
      //   z-index: -2;
    }
    // min-width: 1200px;
    .detect_left {
      width: 45%;
      margin: 50px 0 0 50px;
      z-index: 9;
      .left_title {
        height: 40px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          color: #900;
          height: 40px;
          line-height: 40px;
        }
      }
      .txt {
        p {
          font-size: 16px;
          text-indent: 2em;
          margin-top: 30px;
          line-height: 30px;
        }
      }
    }
    .detect_right {
      width: 50%;

      img {
        width: 100%;
      }
    }
  }
  .see {
    // z-index: 9;
    margin-top: 200px;
    height: 280px;
    width: 100%;
    background: url("../../../../assets/imgs/Writefull素材/未展开02.png");
    background-size: 100% auto;
    box-shadow: inset 100px -100px 100px 50px #ffffff;

    // box-shadow: inherit;
    position: relative;
    .check_btn {
      position: absolute;
      bottom: 20px;
      left: 50%;
      //   top: 50%;
      transform: translate(-50%, -50%);
      .el-button {
        // width: 100px;
      }
    }
  }
  .details {
    .description {
      display: flex;

      img {
        width: 49%;
      }

      .text {
        width: 50%;
        margin: 50px 0 0 50px;
        .left_title {
          height: 40px;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            color: #900;
            height: 40px;
            line-height: 40px;
          }
        }
        .txt {
          margin-top: 20px;
          .txt_title {
            font-weight: 700;
            color: #555;
            font-size: 16px;
            margin: 10px 0;
          }
          p {
            text-indent: 2em;
            line-height: 30px;
            font-size: 16px;
          }
        }
      }
    }
    .illustrate {
      .ill_title {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          color: #900;
          height: 40px;
          line-height: 40px;
        }
      }
      .txt {
        margin-top: 20px;

        .txt_title {
          font-weight: 700;
          color: #555;
          font-size: 16px;
          margin: 20px 0;
        }
        p {
          text-indent: 2em;
          line-height: 30px;
          font-size: 16px;
        }
        img {
          display: inline;
          width: 100%;
          padding: 0 10%;
        }
      }
    }
    .question {
      margin-top: 60px;
      h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: #203c82;
      }
      h3 {
        text-align: center;
        font-size: 16px;
        margin-top: 15px;
        opacity: 0.8;
        color: #888;
      }
      ul {
        margin-top: 30px;
        padding: 0 30px;
        li {
          width: 100%;
          margin-bottom: 30px;
          border-bottom: 1px dashed #203c82;
          font-size: 16px;
          line-height: 28px;
          h4 {
            line-height: 16px;
            color: #4461a8;
            padding-left: 10px;
            border-left: 3px solid #203c82;
          }
          p {
            color: #555;
            padding: 20px 60px 29px 30px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.content {
  display: flex;
  justify-content: space-between;
}
.part1 {
  position: relative;
  z-index: 3;
}
.txt_wrap {
  width: 480px;
  padding: 20px;
}
.red {
  color: #990000;
}
.left_m {
  margin: 100px 0 0 50px;
}
.title {
  img {
    width: 40px;
    vertical-align: middle;
  }
  span {
    font-size: 20px;
    font-weight: 700;
  }
}
.txt {
  color: #666;
  font-size: 16px;
  line-height: 2;
  .txt_h3 {
    // font-weight: 700;
    color: #555;
    font-size: 16px;
  }
  p {
    text-indent: 2em;
    font-size: 16px;
  }
  li {
    margin-bottom: 10px;
  }
}
.btn_wrap {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.btn {
  display: flex;
  width: 140px;
  height: 40px;
  margin-left: 16px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background-color: #1296db;
  cursor: pointer;
}
.h2_title {
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  color: #203c82;
}
.pic {
  width: 420px;
  height: 280px;
  margin-left: 60px;

  img {
    position: relative;
    left: -22px;
    top: -22px;
    width: 100%;
  }
}
.part2 {
  position: relative;
  z-index: 3;
  margin-top: 240px;
}
.w_620 {
  width: 550px;
}
.w_700 {
  width: 700px;
}
.m_t_30 {
  margin-top: 30px;
}
.m_t_50 {
  margin-top: 50px;
}
.m_r_30 {
  margin-right: 30px;
  img{
    position: relative;
    left: -50px;
    top: -22px;
    width: 90%;
  }
}
.m_l_30 {
  margin-left: 30px;
}
.p_t_100 {
  padding-top: 0px;
}
.report {
  p + p::before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background: #0f8be6;
  }
}
.one_btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .el-button {
    width: 100px;
  }
}
.function {
  background: none;
  color: #b14a75;
  font-weight: 600;
  font-size: 15px;
  width: 120px;
}
</style>
