// scholarcy模块
import request from '@/utils/request_part_writful'
import qs from 'qs'


//  用户注册
export function writefulUser(data) {
    return request({
        url: '/api/qingli/writ/login',
        method: 'post',
        data,
        // data: qs.stringify(data),
        headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        // data: qs.stringify(data),
    })
}

// 获取用户信息
export function getWriteful(query) {
    return request({
        url: '/api/qingli/writ/login',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 查看任务列表
export function detail_list(query) {
    return request({
        url: 'api/qingli/writ/detail',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}




// 上传文件
export function input_file(data) {
    return request({
        url: 'api/qingli/writ/input_file',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}





//查看使用量
export function get_limit(query) {
    return request({
        url: '/api/qingli/writ/get_limit_num',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
//查看使用量
export function delete_detail(query) {
    return request({
        url: 'api/qingli/writ/delete_detail',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

