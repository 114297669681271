import request from '@/utils/request'
import qs from 'qs';
/**
 * 提交反馈
 */
  export function checks(formData) {
    return request({
      url: 'extend/upload_file/',
      method: 'post',
      data: formData,
      cache: false,
      processData: false,
      contentType: false,	
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      
    })
  }

   /**
 * 获取用户今年查重、校对使用量
 */
 export function userAge(query) {
    return request({
      url: 'extend/user_usage/',
      method: 'get',
      params: query
      
    })
  }

  /**
 * 下载报告
 */
export function downloadable(data) {
    return request({
      url: 'extend/download_file/',
      method: 'post',
      data
    })
  }
  

   /**
 * 删除报告
 */
export function deleteFile(data) {
    return request({
      url: 'extend/delete_paper_task/',
      method: 'post',
      data
    })
  }
  