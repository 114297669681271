<template>
  <div class="introduce">
    <div class="detect">
      <div class="detect_left">
        <div class="left_title">
          <img
            src="../../../../assets/imgs/查重与校对/检测系统介绍.png"
            alt=""
            style="width: 40px"
          />
          <span>【检测系统介绍】</span>
        </div>
        <div class="txt">
          <p>
            本学术不端检测系统依托先进精准的搜索技术和不断扩充的庞大数据库，专为科研人员提供强有力的支持。系统能够快速识别论文中的不当引用或潜在的剽窃行为，从而帮助提升论文的整体质量。该系统具备多语言检测能力，支持英语、法语、日语、俄语、德语、韩语、阿拉伯语、西班牙语等多种语言的论文检测。此外，系统兼容多种文件格式，包括TXT、PDF、DOC、DOCX等，用户可以根据需求上传不同格式的文档进行检测。
          </p>
        </div>
      </div>
      <div class="detect_right">
        <img src="../../../../assets/imgs/查重与校对/scene-a.png" alt="" />
      </div>
    </div>
    <div style=" display: flex;justify-content: center;">
      <div class="see" v-if="concise">
      <div class="check_btn">
        <el-button size="small" type="primary" @click="view" class="function"
          >查看功能介绍</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="checkGo"
          style="margin-top: 50px; width: 100px;font-size:16px;"
          >去查重</el-button
        >
      </div>
    </div>
    </div>
    <div class="details" v-if="detail">
      <div class="illustrate">
        <div class="ill_title">
          <img
            src="../../../../assets/imgs/查重与校对/图标03.png"
            alt=""
            style="width: 40px"
          />
          <span>【检测报告说明详情介绍】</span>
        </div>
        <div class="txt">
          <h3 class="txt_title">1、检测报告介绍</h3>
          <p>检测报告共有4种版本，HTML与PDF各2种（含引用与不含引用）；</p>
          <p style="margin-top: 12px">
            其相似指数（Similarity Index）是指论文最终的重复率，其计算公式=相似文字总数/文本字数。其中，依来源标示相似度（Similarity by Source）含三部分，即网络来源（Internet Sources）指的是互联网来源；出版物（Publications）指的是报纸、期刊及其他出版物；学生文稿（Student Papers）指的是之前学生提交过的论文。依来源标示相似度的三部分存在交集，所以三者相加的结果要大于相似度指标。
          </p>
          <h3 class="txt_title">2. HTML版本检测报告形式及说明如下图</h3>
          <img
            src="../../../../assets/imgs/查重与校对/检测报告说明01.png"
            alt=""
          />
          <h3 class="txt_title">3. PDF版本检测报告形式及说明如下图</h3>
          <img
            src="../../../../assets/imgs/查重与校对/检测报告说明02.png"
            alt=""
          />
          <img
            src="../../../../assets/imgs/查重与校对/检测报告说明03.png"
            alt=""
          />
          <img
            src="../../../../assets/imgs/查重与校对/检测报告说明04.png"
            alt=""
          />
          <img
            src="../../../../assets/imgs/查重与校对/检测报告说明05.png"
            alt=""
          />
          <h3 class="txt_title">4、 检测报告上的颜色表示什么？</h3>
          <p>
            检测系统把连续的5个词视为剽窃。在检测报告中，各个句子，段落用颜色标示相似指数来源及剽窃程度，有蓝带、绿带、褐带、紫带、红带等多种颜色。不同颜色表示不同的引用来源，颜色越深，剽窃率越高。
          </p>
        </div>
      </div>
      <div class="question">
        <h2>
          <img
            src="../../../../assets/imgs/查重与校对/图标04.png"
            alt=""
          />常见问题
        </h2>
        <h3>以下为客户经常咨询的问题</h3>
        <ul>
          <!-- <li>
            <h4>检测需要多久？</h4>
            <p>
              检测约需20分钟生成报告。报告仅保留7天，请及时下载保存。
            </p>
          </li> -->
          <li>
            <h4>
              检测报告中，去除引用与包含引用的相似指数的差异有多大？
            </h4>
            <p>
              引用情况不同会影响检测报告的相似指数。通常，去除引用与包含引用的相似指数相差约5%。
            </p>
          </li>
          <li>
            <h4>检测报告的相似指数为多少才算合格？</h4>
            <p>
              似指数无固定标准，应根据各机构或出版社的要求确定，且要求存在差异。
            </p>
          </li>
          <li>
            <h4>检测后，文章会被比对数据库收录吗？</h4>
            <p>
              检测不会导致文章被收录。如果检测后相似指数为100%，说明文章被收录
            </p>
          </li>
          <li>
            <h4>检测报告单上的颜色表示什么？</h4>
            <p>
              检测系统把连续的5个词视为剽窃。在检测报告中，各个句子，段落用颜色标示相似指数来源及剽窃程度，有蓝带、绿带、褐带、紫带、红带等多种颜色。不同颜色表示不同的引用来源，颜色越深，剽窃率越高。
            </p>
          </li>
          <li style="border: none">
            <h4>检测时，如何正确编写参考文献格式？</h4>
            <p>
              如果提交的References格式不正确，检测系统将无法识别和排除参考文献，导致不同版本的检测报告结果相同。正确的参考文献格式必须是“References”独占一排，不能添加任何的字符数。
            </p>
          </li>
        </ul>
      </div>
      <div class="details" v-if="detail">
        <div class="illustrate">
          <div class="ill_title">
            <span>免责声明</span>
          </div>
          <div class="txt">
            <p>本“外文学术不端文献检测系统”仅为用户提供文本相似度检测服务，旨在帮助用户识别可能存在的文本相似性问题，并提高学术写作的原创性和规范性。使用本系统所生成的报告和结果仅供参考，具体的查重结果需根据用户所属机构的具体要求进行核实和确认。</p>
            <h3 class="txt_title">1、有限性</h3>
            <p>本系统的检测结果基于数据库中已收录的文献、公开数据及其他合法途径获取的资源。然而，因数据库覆盖范围的限制，本系统无法保证能检测到所有的相似内容或抄袭行为。系统所提供的相似度结果不代表对学术不端行为的最终判定。</p>
            <h3 class="txt_title">2、责任免除</h3>
            <p>
              本系统对用户上传的文档内容的安全性和保密性采取了严格的措施，但由于互联网环境的复杂性，我们无法完全排除数据泄露的风险。用户应自行承担因使用本系统而可能引起的任何损失或后果。本系统对因技术故障、数据传输问题或其他不可抗力因素导致的结果错误或信息泄露不承担任何责任。
            </p>
            <h3 class="txt_title">3、版权声明</h3>
            <p>
              用户在使用本系统进行查重检测时，应确保其上传的文档不侵犯第三方的版权、专利或其他知识产权。因用户上传的文档内容而产生的任何法律纠纷或责任，由用户自行承担。
            </p>
            <h3 class="txt_title">4、使用规范</h3>
            <p>
              用户应合法、合理地使用本系统，不得利用本系统从事任何违反法律法规或侵犯他人权利的行为。若发现用户有违规行为，本系统有权立即停止其使用权利，并保留进一步追究法律责任的权利。
            </p>
            <h3 class="txt_title">5、报告使用</h3>
            <p>
              本系统生成的查重报告仅供用户参考，最终解释权归所属学术机构所有。用户在使用本系统时，需明确知晓并理解，报告结果不具备法律效力，且不代表任何形式的学术评判或认可。
            </p>
          </div>
        </div>
      </div>
      <div class="one_btn">
        <el-button size="small" type="primary" @click="checkGo"
          >去查重</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      concise: true,
      detail: false,
    };
  },
  created() {},
  methods: {
    // 查看功能介绍
    view() {
      this.concise = false;
      this.detail = true;
    },
    // 显示查重页面
    checkGo() {
      this.$emit("checkup", "true");
    },
  },
};
</script>

<style  lang='less' scoped>
.introduce {
  // height: 100vh;
  color: #555;
  //   position: relative;
  .bottom {
    z-index: 10;
    // background: rgb(223, 223, 223);
    position: fixed;
    bottom: 150px;
    right: 100px;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    font-size: 20px;
    cursor: pointer;
    i {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
  }
  .detect {
    display: flex;
    // width: 100%;
    // width: 1200px;
    // min-width: 1200px;
    .detect_left {
      width: 50%;
      margin: 50px 0 0 50px;
      .left_title {
        height: 40px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          color: #900;
          height: 40px;
          line-height: 40px;
        }
      }
      .txt {
        p {
          text-indent: 2em;
          margin-top: 30px;
          line-height: 30px;
          font-size: 16px;
        }
      }
    }
    .detect_right {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
  .see {
    height: 280px;
    width: 100%;
    max-width: 1070px;
    background: url("../../../../assets/imgs/查重与校对/未展开.png");
    background-size: 100% auto;
    box-shadow: inset 100px -100px 100px 50px #ffffff;
    // box-shadow: inherit;
    position: relative;
    .check_btn {
      position: absolute;
      bottom: 20px;
      left: 50%;
      //   top: 50%;
      transform: translate(-50%, -50%);
      .el-button {
        // width: 100px;
      }
    }
  }
  .details {
    .description {
      display: flex;

      img {
        width: 49%;
      }

      .text {
        width: 50%;
        margin: 50px 0 0 50px;
        .left_title {
          height: 40px;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            color: #900;
            height: 40px;
            line-height: 40px;
          }
        }
        .txt {
          margin-top: 20px;
          .txt_title {
            font-weight: 700;
            color: #555;
            margin: 10px 0;
          }
          p {
            text-indent: 2em;
            line-height: 30px;
            font-size: 16px;
          }
        }
      }
    }
    .illustrate {
      .ill_title {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 700;
          color: #900;
          height: 40px;
          line-height: 40px;
        }
      }
      .txt {
        margin-top: 20px;

        .txt_title {
          font-weight: 700;
          color: #555;
          margin: 20px 0;
        }
        p {
          text-indent: 2em;
          line-height: 30px;
          font-size: 16px;
        }
        img {
          display: inline;
          width: 100%;
          padding: 0 10%;
        }
      }
    }
    .question {
      margin-top: 60px;
      h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: #203c82;
      }
      h3 {
        text-align: center;
        font-size: 14px;
        margin-top: 15px;
        opacity: 0.8;
        color: #888;
      }
      ul {
        margin-top: 30px;
        padding: 0 30px;
        li {
          width: 100%;
          margin-bottom: 30px;
          border-bottom: 1px dashed #203c82;
          font-size: 16px;
          line-height: 28px;
          h4 {
            line-height: 16px;
            color: #4461a8;
            padding-left: 10px;
            border-left: 3px solid #203c82;
          }
          p {
            color: #555;
            padding: 20px 60px 29px 30px;
            font-size: 16px;
          }
        }
      }
    }
    .one_btn {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      .el-button {
        width: 100px;
      }
    }
  }
}
.function {
  background: none;
  color: #b14a75;
  font-weight: 600;
  font-size: 15px;
  width: 120px;
}
</style>